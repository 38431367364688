<template>
  <Auth
    title="Enter Your Email"
    description="To acquire a link to reset your password, please enter your email address"
  >
    <template>
      <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
      <div class="mb-4 custom-inputs mt-8">
        <InputBox
          id="email"
          v-model.trim="forgetUser.identifier"
          title="Email"
          name="Email"
          placeholder="Email"
          class="border-none"
          rules="required|email"
          :hide-title="true"
          @keyup.enter="forgetPassword"
        />
        <button
          :disabled="isLoading"
          class="focus:outline-none focus:shadow-md my-5 w-full text-base text-center tracking-wide text-white font-roboto h-12 flex justify-center items-center primary-button"
          @click.prevent="handleSubmit(forgetPassword(invalid))"
        >
          <span v-if="isLoading">
            <Loader />
          </span>
          <span v-else>Continue</span>
        </button>
        <div
          class="mt-6 font-display font-medium text-base font-roboto text-label-text flex items-center justify-items-auto"
        >
          <router-link to="/signin">
            <span class="flex">
              <icon class="icon mr-2" icon="arrowLeft" height="21" width="20" />
              {{ $t(`signUpPage.Sign in`) }}
            </span>
          </router-link>
        </div>
      </div>
      <!-- </ValidationObserver> -->
    </template>
  </Auth>
</template>

<script>
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import Auth from '@layouts/auth.vue'
import icon from '@components/icons/icon.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    Auth,
    icon,
    InputBox,
    Loader,
    // ValidationObserver,
  },
  data() {
    return {
      forgetUser: {
        identifier: '',
      },
      isLoading: false,
    }
  },
  methods: {
    forgetPassword(invalid = false) {
      if (invalid) return
      this.isLoading = true
      let payload = {
        email: this.forgetUser.identifier,
      }
      this.forgotOwnerPassword(payload)
        .then(() => {
          this.$store.commit('toast/NEW', {
            message: 'Reset password link sent to provided account. Please check',
            type: 'info',
            duration: 6000,
          })
          this.$router?.push({ name: 'login' })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    ...mapActions('auth', ['forgotOwnerPassword']),
  },
}
</script>

<style lang="scss" module>
@import '@design';
</style>

<style lang="scss">
@import '@src/router/views/auth/auth.scss';
</style>
